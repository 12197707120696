.productInfoWithBtn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-info-container {
  display: flex;
}

.productInfo {
  border-radius: 15px;
  margin: 0px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.image-and-sizes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
}

.productInfo img {
  width: 300px;
  border-radius: 7px;
  box-shadow: var(--box-shadow-color);
  transition:
    border 0.3s ease,
    box-shadow 0.3s ease;
}

.productInfo .description {
  text-align: center;
  color: var(--text-color);
  transition: color 0.3s ease;
  font-size: 40px;
  text-shadow: 10px 10px 20px #00000017;
}

.productInfo .productInfoPrice {
  text-align: center;
  color: var(--text-color);
  transition: color 0.3s ease;
  font-size: 24px;
  font-weight: bold;
  text-shadow: 10px 10px 20px #00000017;
}

.sizes-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 200px;
}

.addToCartBtn {
  margin-top: 5px;
}

.sizes-subContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.unitsInStock {
  text-align: center;
  margin-top: 5px;
  text-shadow: 10px 10px 20px #00000017;
}

.productSizeContainer {
  border-radius: 4px;
}

.btnContainer {
  display: flex;
  flex-direction: row;
  padding: 10px;
  box-sizing: border-box;
}

.btnContainer input {
  display: none;
}

input[type='radio']:checked + label span {
  transform: scale(1.25);
}

.btnContainer label {
  display: flex;
  width: 31px;
  height: 25px;
  cursor: pointer;
  margin: 5px;
  justify-content: center;
}

.btnContainer label:hover span {
  transform: scale(1.25);
}

input[type='radio'] + label span {
  display: block;
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease-in-out;
  background-color: transparent;
  border: var(--border);
  border-radius: 5px;
  justify-content: center;
  text-align: center;
  padding-top: 6px;
  box-shadow: var(--box-shadow-color);
  transition:
    transform 0.3s ease-in-out,
    background-color 0.3s ease,
    border 0.3s ease,
    box-shadow 0.3s ease;
}

input[type='radio']:checked + label span {
  border: 2px solid goldenrod;
}
