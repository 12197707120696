.shop {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shopTitle {
  text-align: center;
  font-size: 40px;
  color: var(--text-color);
  min-width: 350px;
  transition: color 0.3s ease;
  text-shadow: 10px 10px 20px #00000017;
}

.shopTitle h1 {
  margin: 20px 0px;
}

.products {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
}

.product {
  border-radius: 15px;
  width: 300px;
  height: 350px;
  margin: 50px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.product img {
  width: 300px;
  border-radius: 7px;
  /* border: var(--border-bold); */
  box-shadow: var(--box-shadow-color);
  transition:
    border 0.3s ease,
    box-shadow 0.3s ease;
}

.product-image-container:hover {
  transform: scale(1.25);
  z-index: 2;
  cursor: pointer;
}

.product .description {
  text-align: center;
  color: var(--text-color);
  transition: color 0.3s ease;
}

.product .description p {
  margin: 5px;
  font-size: 20px;
  text-shadow: 10px 10px 20px #00000017;
}

.product:hover {
  transition: 0.3s ease-in;
  cursor: pointer;
}

.addToCartBtn {
  font-family: 'Event Horizon Medium';
  background-color: transparent;
  border: var(--border-bold);
  min-width: 100px;
  padding: 5px 10px;
  border-radius: 5px;
  color: var(--text-color);
  box-shadow: var(--box-shadow-color);
  transition:
    background-color 0.3s ease,
    color 0.3s ease,
    border-color 0.3s ease;
}

.addToCartBtn:hover {
  background-color: var(--text-color);
  color: var(--background-color);
  border: var(--border-bold);
  cursor: pointer;
}
