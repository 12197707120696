.categoryPicker {
  display: flex;
  flex-direction: column;
  width: 200px;
  align-items: center;
}

.categoryPicker label {
  margin-bottom: 10px;
}

#categoryDropdown {
  text-align: center;
  border-radius: 5px;
  border: var(--border-bold);
  background-color: transparent;
  height: 30px;
  color: var(--text-color);
  font-family: 'Event Horizon Medium', monospace;
  transition:
    border 0.3s ease,
    color 0.3s ease;
  box-shadow: var(--box-shadow-black);
}

#categoryDropdown:hover {
  cursor: pointer;
}

#categoryDropdown:focus {
  outline: none;
}

#categoryDropdown option {
  text-align: center;
  border-radius: 5px;
  background-color: transparent;
  font-family: 'Event Horizon Medium', monospace;
  color: var(--black);
}

#categoryDropdown option:hover {
  cursor: pointer;
}
