.hamburgerMenuContainer {
  position: relative;
  margin-right: 20px;
}

.profilePic {
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: var(--transition);
  border-radius: 5px;
}

.profilePic:hover {
  transform: scale(1.25);
}

/* .hamburger-icon {
  cursor: pointer;
  width: 30px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: var(--transition);
}

.hamburger-icon:hover {
  transform: scale(1.25);
}

.hamburger-icon span {
  display: block;
  height: 4px;
  background-color: #000;
  border-radius: 5px;
} */

.menu-options {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  right: -100%;
  background-color: #bdbdbd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: right 0.5s ease-in-out;
  border-radius: 5px;
  padding: 15px;
  z-index: 100;
  height: 100%;
  width: 200px;
}

.menu-options.open {
  right: 0;
  transition: right 0.5s ease-in-out;
}

.menu-options .close-btn {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  align-self: flex-end;
  color: var(--black);
}

.menu-options.open .close-btn:hover {
  transform: scale(1.25);
  transition: transform 0.3s ease;
}

.menu-options .signed-in {
  margin-top: auto;
  padding: 10px 0;
  margin-bottom: 50px;
  font-size: 14px;
  color: #333;
}
